.destination-search {
  @extend .columns;

  margin: 1rem 0 1rem 0;
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  .search-form-field-quotes {
    flex: 0.19;

    .content,
    .checkbox-content {
      @extend .column;

      background: white;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
      display: flex;
      padding: 0; // 0.5rem;
      margin-bottom: 0;

      input {
        flex: 1;
        border: none;
        font-size: 0.95rem;
        padding: 0.8rem 0.5rem;
      }

      .search-icon {
        border: none;
        background: none;
        padding: 0 0 0 0.5rem;
        flex: 0.2;
        color: $gold;
      }
    }

    .checkbox-content {
      box-shadow: none;
      padding-left: 0;
      padding-right: 1.5rem;

      label {
        min-width: 110px;
        font-size: 0.9rem;
      }
    }
  }

  .search-form-field {
    flex: 0.25;
    margin-right: 7rem;

    .content,
    .checkbox-content {
      @extend .column;

      background: white;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
      display: flex;
      padding: 0;
      margin-bottom: 0;

      .checkbox-field {
        min-width: 150px;
      }

      input {
        flex: 1;
        border: none;
        font-size: 0.95rem;
        padding: 0.8rem 0.5rem;
      }

      .search-icon {
        border: none;
        background: none;
        padding: 0 0 0 0.5rem;
        flex: 0.2;
        color: $gold;
      }
    }

    .checkbox-content {
      box-shadow: none;
      margin-top: -0.3rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      .label {
        min-width: 110px;
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.container-trip-builder {
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}

.container-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  padding: 2rem;
}

.container-for-image {
  display: flex;
  border: 2px solid #c3b289;
  width: 17rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.text-button {
  color: $gold;
  padding: 1rem;
  border: 2px solid gray;
  font-weight: bold;
  font-size: 1rem;
}

.input-for-images {
  display: none;
}

.image {
  width: 17rem;
  height: 15rem;
  object-fit: cover;
}

.input-container {
  margin: 2rem 0;
}

.button-container {
  display: flex;
  flex: 0.23;
  justify-content: space-between;
}

.button-save {
  width: 45%;
  background-color: #424242;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}

.button-cancel {
  width: 45%;
  background-color: white;
  color: black;
  border: 2px solid gray;
  border-radius: 0;
  padding: 0 1rem;
}

.button-for-navigation {
  border: none;
  color: $gold;
  background-color: white;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.container-for-center-elements {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-button {
  width: 2rem;
  height: 2rem;
  color: $gold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  background-color: #f3f3f3;
  border: 1px solid $gold;
  border-radius: 50%;
  margin: 0.8rem;
}

.checked-destination-acive {
  margin: 0 2rem;
  color: $gold;
}

.description-text {
  font-size: 0.7rem;
}

.preview-button {
  float: right;
  font-weight: bold;
  font-size: large;
  text-decoration-line: underline;
  border: none;
  color: $gold;
  background-color: white;
}

.label-inputs-text {
  font-weight: bold;
  font-size: large;
}

.tabs-style {
  font-size: 0.8rem;
}

.card-container {
  margin: 1rem 0 1rem 0;
  width: 100%;
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;

  .card {
    flex: 0.18;
    flex-direction: column;
    height: 6rem;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    float: left;
    padding: 1rem;

    &.activeCard {
      border-bottom: solid 0.3rem $gold;
    }

    .titleCard {
      font-size: 1rem;
      padding: 1.2rem;
    }
  }
}

.container-build-quotes {
  .tab-item {
    font-size: 1.2rem;
  }
}
