.login-page,
.reset-pass-page {
  @extend .columns;

  display: flex;
  width: 100vw;

  .notification-box {
    height: 4rem;
  }

  .login-section {
    @extend .column;
    @extend .is-half;

    align-items: center;
    //background-color: green;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    max-height: 100vh;
    padding-left: 2rem;

    .login-section {
      //flex: 0.5;
    }

    h1 {
      //margin-top: 20vh;
    }
  }

  .login-section-form {
    .checkbox-field {
      .checkbox {
        margin: 0;
      }
    }
  }

  .login-image {
    background: url('../assets/images/loginImage.png') no-repeat;
    background-size: cover;
    align-items: flex-end;
    min-height: 102vh;

    span {
      color: white;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
}
