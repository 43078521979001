.profile-details-container {
  @extend .client-basic-details-container;

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
  }

  hr {
    margin: 1rem 0;
  }

  .profile-form-set {
    @extend .client-details-form;

    margin: 1rem 0;

    .input-field {
      flex: 0.33;

      input,
      .input {
        min-width: 18rem !important;
      }
    }
  }
}
