body {
  margin: 0;
  min-height: 102vh;
  padding: 0;
}

a {
  font-weight: 500;
}

input {
  outline: none;
}

.input-field,
.input,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

.is-in-center {
  margin: auto;
}

.pt1 {
  padding-top: 1rem;
}
