.marketing-intro {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.marketing-illustration {
  img {
    object-fit: contain;
  }
}
