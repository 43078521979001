.container-clients {
  .tab-item {
    color: lighten(black, 60%);
    font-size: 1.3rem;

    &.active {
      color: black;
    }
  }

  .tab-bar-title {
    padding: 3rem 0 2rem 0;
  }

  .tab-container:not(:last-child) {
    margin-bottom: 0;
  }

  .client-search-container {
    display: flex;
    align-items: center;
    margin-right: -0.7rem;

    .filter-picker {
      .filter-btn {
        border: none;
        background: white;
        box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
        width: 10rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
        font-size: 0.95rem;
        padding: 0.8rem 0.5rem;
      }

      .filter-options {
        display: flex;
        flex-direction: column;
      }
    }

    .content {
      background: white;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
      display: flex;
      padding: 0; // 0.5rem;
      margin-bottom: 0;
      width: 25rem;

      input {
        flex: 1;
        border: none;
        font-size: 0.95rem;
        padding: 0.8rem 0.5rem;
      }

      ::placeholder {
        font-weight: normal;
      }

      .search-icon {
        border: none;
        background: lighten(rgba(0, 0, 0, 0.1), 10%);
        padding: 0 0.8rem;
        flex: 0.2;
        color: $gold;
        max-width: 3rem;
        font-size: 1.2rem;

        &:hover {
          background: lighten(rgba(0, 0, 0, 0.05), 25%);
          cursor: pointer;
          color: black;
        }
      }

      .clear-button {
        background: white;
        border: none;
        padding: 0.5rem 1rem;
        cursor: pointer;
      }
    }
  }

  .container-client-list {
    .button-for-navigation {
      margin-bottom: 3rem;
    }
  }
}

td.client-info {
  padding: 0;

  button {
    color: black;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem 0.5rem 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;

    .icon {
      margin-right: 0.7rem;
      position: relative;
      top: 0.3rem;
    }
  }

  &:hover {
    background: $gold;

    .icon {
      color: white;
    }
  }
}

.container-new-client {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  margin-bottom: 4rem;

  .client-basic-details-container {
    flex: 0.8;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);

    .title-box {
      padding: 1rem 2rem;
    }

    label {
      font-size: 1rem;
      font-weight: 900;
    }

    .title-border {
      width: 2rem;
      height: 0.5rem;
    }

    .title-text {
      font-size: 1.3rem;
    }

    .container-for-image {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .icon {
        width: 5rem;
        height: 5rem;
        border: 1px solid $gold;
        border-radius: 50%;
      }

      .text-button {
        border: none;
        color: black;
        font-weight: bold;
      }

      .image {
        width: 100%;
        height: 13rem;
      }
    }

    .hr {
      height: 0.5rem;
    }

    .client-details-form {
      padding: 0 2rem 0 1.5rem;
      display: flex;
      //flex: 1;
      flex-direction: row;
      align-content: space-between;
      flex-wrap: wrap;
      justify-content: space-between;

      ::placeholder {
        font-weight: normal;
      }

      .input-field {
        flex: 0.3;
        max-width: 50%;
        margin-bottom: 0.5rem;

        p {
          min-width: 3rem;
        }

        input {
          min-width: 12rem;
        }
      }
    }

    .bottom-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 2rem;
      //padding: 0 2rem;

      .button-container {
        display: flex;
        flex-direction: row;
        margin: 1rem 1.5rem;
        justify-content: flex-start;

        //.input-field {
        //  padding-bottom: 0.5rem;
        //  //padding-right: 3rem;
        //
        //  ::placeholder {
        //    font-weight: normal;
        //  }
        //}
        //
        //.input-field input {
        //  min-width: 40%;
        //}

        button {
          padding: 1rem 2rem;
          width: auto;
          border-radius: 0.2rem;
          margin-right: 1rem;
        }

        .button-save {
          //padding: 1rem 5rem;
          //background-color: #424242;
          //border-radius: 0;
          //display: flex;
          //justify-content: center;
          //align-content: center;
        }

        .button-cancel {
          //padding: 1rem 5rem;
          //background-color: white;
          //color: black;
          //border: 2px solid gray;
          //border-radius: 0;
          //display: flex;
          //justify-content: center;
          //align-content: center;
        }
      }
    }
  }

  .client-advanced-container {
    //width: 58rem;
    flex: 1;
    //height: 55rem;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 60%;

    .title-box {
      padding: 1rem 2rem;
    }

    .title-border {
      width: 2rem;
      height: 0.5rem;
      margin-bottom: 0;
    }

    .title-text {
      font-size: 1.3rem;
    }

    .container-for-slider {
      min-width: 100%;

      .slider {
        min-width: 100%;

        .title-text {
          font-size: 1.3rem;
          color: gray;
        }
      }

      .cards-slider {
        overflow: hidden;
        white-space: nowrap;
        //width: 40rem;
        height: 15rem;
        position: relative;
        display: flex;
        align-items: center;
        //margin-right: 0.5rem;

        .slider-btns {
          margin-bottom: 3rem;
        }

        .card {
          text-align: left;
          position: relative;
          display: inline-block;
          left: 6rem;
          margin-right: 3rem;
          padding: 1rem 1rem 2rem 1rem;
          vertical-align: top;
          box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
          width: 20rem;
          background: linear-gradient(to right, white, $gold);
          color: gray;

          .title-cards {
            font-size: 2.2rem;
            font-weight: 600;
            color: black;
          }

          .desc {
            font-size: 1.5rem;
            margin-bottom: 1rem;
          }

          .price {
            font-size: 1rem;
            color: black;
            font-weight: 500;

            strong {
              font-weight: bold;
              font-size: 1.5rem;
            }
          }
        }

        .slider-btn {
          font-size: 2.5rem;
          text-align: center;
          color: $gold;
          border: 0;
          cursor: pointer;
          background: none;
          position: absolute;
          z-index: 999;
        }

        .btn-r {
          left: 26.5rem;
        }

        .btn-l {
          left: 3rem;
        }
      }
    }

    .button-container-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 2rem;
      padding-right: 10rem;
      padding-top: 8rem;
    }

    .text-container {
      padding: 2rem 1rem;
      display: flex;
      color: #979797;
      flex-direction: column;

      textarea.text {
        border-color: rgba(0, 0, 0, 0.2);
        border-bottom: 0.3rem solid black;
        padding: 1rem;
        font-size: 1rem;
        flex: 1;
        margin: 0.5rem 2rem 1rem 2rem;
        min-height: 15rem;
      }
    }

    .container-for-message {
      width: 50rem;
      height: 100%;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
      padding: 0.5rem 0.8rem;

      .messages-box {
        height: 95%;
        width: 100%;

        .message {
          font-size: 0.8rem;
          padding: 0.5rem 0.8rem;
          width: 12rem;
          border: 1px solid black;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0;
          background-color: white;
        }
      }

      .inside-button {
        position: relative;
        display: flex;
        align-items: center;

        .input-chat {
          width: 100%;
          padding: 0.5rem 0.8rem;
          border: 1px solid black;
          outline: none;
        }

        .button-chat {
          position: absolute;
          right: 0.3rem;
          border: none;
          height: 1.9rem;
          width: 1.9rem;
          border-radius: 50%;
          color: white;
          background-color: $gold;
        }

        .button-chat:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.invite-new-client-container {
  padding-bottom: 10rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 0.5rem;

  .clients-container-users {
    width: 24.5rem;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);

    .tab-item {
      font-size: 1rem;
      font-weight: normal;
      padding-bottom: 0.8rem;
      padding-right: 2rem;
      color: #0a0a0a;

      &.active {
        color: $gold;
      }
    }

    .inputAll {
      margin-right: 0.5rem;
      color: #0a0a0a;
    }

    .title-style {
      padding: 1.3rem 1.3rem 0.5rem 1.3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button-delete {
        color: red;
        border: none;
        background-color: white;
        font-size: 1.5rem;
      }

      .tab-bar-title {
        padding: 0 0 0.3rem 0;
      }
    }

    .button-container-for-message {
      padding: 1rem;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .messages-button {
      border: none;
      background-color: white;
      font-size: 0.95rem;
    }

    .list-users-items {
      .item-user-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        .user-name {
          font-weight: bold;
        }

        .messages-button {
          color: $gold;
        }

        .user-email {
          font-size: 1rem;
          color: gray;
        }
      }
    }
  }

  .container-update-user {
    width: 55rem;
    height: 56rem;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    margin-top: -5rem;

    .container-for-update {
      margin-left: 35rem;
      width: 20rem;
      height: 100%;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
      padding: 0.5rem 1rem;

      .title-box {
        margin-top: 2rem;
        padding-bottom: 0.3rem;

        .title-text {
          font-weight: normal;
          font-size: 1rem;
        }

        .title-border {
          height: 0.5rem;
        }
      }

      .container-change-color {
        .title-text {
          font-weight: normal;
          font-size: 0.8rem;
        }

        .button-color-white {
          background-color: white;
          border: 1px solid black;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }

        .button-color-black {
          background-color: black;
          border: 1px solid black;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          margin: 0 0.5rem;
        }

        .button-color-gold {
          background-color: $gold;
          border: 1px solid $gold;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }

        .button-choose-action {
          background-color: white;
          border: 1px solid $gold;
          color: $gold;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          margin-left: 10rem;
        }

        .select-input {
          padding: 0.5rem;
          width: 80%;
          margin-bottom: 1rem;
        }
      }

      .container-for-image {
        border: none;
        padding: 3rem 0;
        width: 100%;
        height: 0;
        display: flex;

        .icon-for-input-button {
          display: flex;
          align-items: center;
          color: $gold;
          font-size: 2rem;
        }

        .description {
          font-size: 0.8rem;
        }

        .text-button {
          font-size: 1.2rem;
          border: none;
          font-weight: normal;
          color: black;
        }
      }

      .button-container-details {
        display: flex;
        flex-direction: row;
        margin: 1rem 1rem 1rem -1rem;
        position: relative;
        bottom: 0;

        .button-cancel {
          width: 50%;
        }

        .button-save {
          width: 50%;
        }
      }
    }
  }
}

.client-edit-container {
  display: flex;
  flex-direction: row;

  a {
    padding: 0 0.5rem;
    margin-right: 0.5rem;
  }
}

button.client-info {
  color: $gold;
  font-size: 1.1rem;
  border: none;
  background: none;
  cursor: pointer;
}

.client-info-container {
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  position: fixed;
  margin: auto;
  align-items: center;

  .info-content {
    min-width: 35vw;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    margin: auto;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h1 {
      font-size: 1.2rem;
      margin: 0 0 1rem 0;
      padding: 0;
    }

    .close-button {
      border: none;
      padding: 0.5rem 1rem;
      align-self: flex-end;
      cursor: pointer;
      border-radius: 0.2rem;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
