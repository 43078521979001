.nav-bar {
  background: $grey-light;
  align-items: center;
  margin-bottom: 2rem;

  .nav-bar-container {
    @extend .container;
    @extend .columns;

    margin: 0 auto;
  }

  .nav-link {
    @extend .column;

    padding: 1rem 0;
    font-weight: 400;
    color: $grey-dark;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 2rem;
      height: 1.5rem;
      margin: 0 0.5rem 0 0;
      object-fit: contain;
    }

    span {
      display: inline-flex;
      margin-top: 0.2rem;
    }
  }

  .nav-link:hover,
  .active-link {
    background: white;
    color: $gold;
  }
}

.profileItem,
.notification-item {
  .menu-drop-down {
    display: none;
    flex-direction: column;
    box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.3);
    margin-left: -9rem;

    a {
      color: black;
    }

    .profile-cell {
      padding: 0.5rem;
      background: white;
      color: black;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  .menu-drop-down-active {
    display: flex;
    position: absolute;
    margin-top: 2.3rem;
    width: 15rem;
    z-index: 9999;

    li {
      width: 100%;
      padding-top: 0.2rem;
      background: white;

      h2 {
        font-size: 1rem;
        padding: 0 0.5rem;
        font-weight: 500;
      }

      h3 {
        font-size: 0.9rem;
        padding: 0 0.5rem;
        font-weight: 500;
      }

      a {
        padding: 0.5rem 1rem;
        min-width: 10rem;
        display: block;
        font-size: 0.9rem;

        &:hover {
          color: $gold;
        }
      }
    }

    li:last-child {
      border-bottom: solid 0.2rem $gold;
    }
  }
}

.notification-item:hover {
  .menu-drop-down {
    margin-top: 0;
    margin-left: -0.5rem;
  }
}

.profile-menu-button {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.5rem;
  border: none;
  margin-top: -0.3rem;
  padding: 0.3rem 0.8rem;
  height: 2rem;

  &:hover {
    cursor: pointer;
  }
}
