.button {
  @extend .button;

  border-radius: 0;
  text-transform: uppercase;
}

.primary-button {
  padding: 0.5rem;

  @include actionButton($gold);

  font-size: 1rem;
}

.dark-button {
  @include actionButton($grey-dark, 1.1rem);
}

.wire-button {
  @include actionButton($grey-dark, 1.1rem);

  border: solid 1px black;
  color: black;
  background: transparent;
}

.wired-button {
  border: solid 0.15rem $grey-dark;
  color: $grey-dark;
  background: white;

  &:hover {
    background: $grey-dark;
    color: white;
  }

  &:active {
    background: lighten($grey-dark, 25%);
    color: white;
  }
}

.secondary {
  color: white;
  background: $primary_gold !important;
  font-size: 1.1rem;
  padding: 0.8rem;
  text-transform: uppercase;
}
