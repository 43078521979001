h1 {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.title-box {
  padding-bottom: 2rem;

  h1 {
    padding-bottom: 0;
    margin-bottom: 0;
    text-transform: uppercase;

    a {
      font-size: 1rem;
      text-transform: capitalize;

      .icon {
        margin-right: 0.3rem;
        font-size: 1.5rem;
        position: relative;
        top: 0.2rem;
        margin-left: 1rem;
      }
    }
  }

  .title-border {
    height: 0.8rem;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    width: 2.5rem;
    background: $gold;
    display: block;
  }
}

.price-amount {
  text-transform: uppercase;
  font-size: 1.6rem;
  color: $grey-dark;
}

.price-currency {
  font-size: 1.5rem;
}

.status-info {
  padding: 0.3rem 0.8rem 0.4rem 0.8rem;
  border-radius: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
  overflow: hidden;
  font-weight: 600;
}

.pending-status {
  @extend .status-info;

  display: inline-flex;
  background: lighten(#1d72aa, 50%);
}

h3 {
  @extend h2;

  font-size: 0.9rem;
  padding-left: 0;
}

.lower-text {
  text-transform: lowercase;
}
