@mixin actionButton($color: $gold, $size: 1rem) {
  @extend .button;
  @extend .is-primary;

  font-weight: bold;
  font-size: $size;
  background: $color;
  padding: 1rem 2rem;
  border-radius: 0.3rem;

  &:hover {
    background: lighten($color, 15%);
  }

  &:active {
    background: darken($color, 10%);
    color: darken(white, 10%);
  }
}

@mixin placeholder($fontSize: 0.9rem) {
  font-size: $fontSize;
  font-weight: bold;
  font-family: $family-primary;
  color: #000;
}

@mixin iconButton($size: 1.1rem, $color: $gold) {
  box-shadow: none;
  color: $color;
  border: none;
  background: transparent;
  font-size: $size;
  padding: 0.5rem;
  cursor: pointer;
}
