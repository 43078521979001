table {
  width: 100%;
  margin-top: 2vw;

  th {
    text-transform: uppercase;
    background: $table-header;
    padding: 1rem 0.5rem;
    font-size: 0.9rem;
  }

  tr {
    td,
    th {
      padding: 0.5rem;
      text-align: center;

      &.left-align {
        text-align: left;
        padding-left: 1rem;
      }
    }
  }
}

.clients-list,
.destinations-list {
  .client-item,
  .destination-item {
    td,
    th {
      p {
        font-size: 0.9rem;
        text-align: center !important;
      }

      &.left-align p {
        text-align: left !important;
      }
    }
  }

  .destination-item {
    td {
      img {
        max-width: 4rem;
      }
    }
  }
}

.clients-cell {
  max-width: 5rem;

  p.missing-info {
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }

  .wire-button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

.edit-order-buttons {
  display: flex;
  padding-top: 0.5rem;

  a,
  button {
    padding: 0.3rem;
    border: none;
    background: none;
    color: $gold;
    fill: $gold;
    font-size: 1.1rem;
    cursor: pointer;

    &:first-child {
      margin-right: 0.3rem;
    }
  }
}
