.react-confirm-alert {
  .react-confirm-alert-body {
    color: $grey-dark;
    font: $primary-font;
    font-size: 1rem;
    min-width: 26.5rem;

    h1,
    button {
      font: $primary-font;
      padding: 0;
    }

    h1 {
      margin-bottom: 1rem;
    }

    .react-confirm-alert-button-group {
      button {
        @extend .dark-button;

        //@include actionButton($grey-dark, 0.9rem);
        font-size: 0.9rem !important;
        padding: 0.5rem 1rem;
        //border-radius: 0.3rem !important;
      }

      button:nth-child(1n+2) {
        @include actionButton($grey-dark, 0.9rem);

        border: solid 0.15rem $grey-dark;
        padding: 0.5rem 1rem;
        color: $grey-dark;
        background: white;
        border-radius: 0.3rem !important;

        &:hover {
          background: $grey-dark;
          color: white;
        }

        &:active {
          background: lighten($grey-dark, 25%);
          color: white;
        }
      }
    }
  }
}
