.container-dashboard {
  @extend .columns;

  justify-content: space-between;
  margin-top: 5rem;
  margin-left: 1rem;

  .card-container-dashboard {
    display: flex;
    flex: 1;
    max-width: 40%;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 1.5rem;
  }

  .card-container-items {
    //width: 26vw;
    height: 27rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    padding: 1vw 1.5vw;
    overflow-y: scroll;
    margin-bottom: 0;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: $gold;
  }

  .title-container {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .title-border-dashboard {
    height: 0.4rem;
    margin-top: 0;
    margin-bottom: 0;
    width: 1.5rem;
    background: $gold;
  }

  .tab-bar-title-dashboard {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    background: none;
    border: none;
    margin-right: 0.5rem;
    color: lighten(black, 40%);
    cursor: pointer;

    &.active {
      border-bottom: solid 0.2rem $gold;
      color: black;
    }
  }

  .list-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem 0;
    align-items: center;

    .title-item {
      font-weight: bold;
      font-size: 1rem;
    }

    .value-item {
      font-size: 1.5vw;
    }
  }

  .card-container-users {
    //width: 26vw;
    height: 27.5rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    padding: 1rem 0.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;

    .clients-container {
      flex: 1;
      padding-right: 1rem;
      overflow-y: scroll;

      ::-webkit-scrollbar {
        width: 2px;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 -5px grey;
      }

      ::-webkit-scrollbar-thumb {
        background: $gold;
      }
    }

    .title-container {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .list-item-users {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    align-items: center;

    .description-users {
      color: rgba(0, 0, 0, 0.4);
      font-weight: 500;
      font-size: 0.8rem;
    }

    .name-container {
      flex: 1;
      padding-left: 0.5em;
    }

    .name-users {
      font-weight: bold;
      font-size: 1rem;
      text-align: left;
      flex: 1;
      margin-bottom: 0;
    }

    .button-item {
      width: 6rem;
      height: 2rem;
      background-color: white;
      border: 2px solid black;
      font-weight: bold;
      font-size: 1rem;
      cursor: pointer;
    }
  }

  .container-diagram-dashboard {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    width: 60rem;
    height: 55.5rem;
    padding: 2rem;

    .card-container {
      margin: 1rem 0 1rem 0;
      width: 100%;
      padding-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;

      .card {
        flex: 0.25;
        flex-direction: column;
        height: 6rem;
        border: none;
        font-size: 1.2rem;
        font-weight: 700;
        float: left;
        padding: 1rem;

        &.activeCard {
          border-bottom: solid 0.3rem $gold;
        }

        .title-card {
          font-size: 0.8rem;
          padding: 0.1rem;
        }
      }
    }

    .container-for-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .selector {
        width: 10rem;
        height: 3rem;
        border: 2px solid black;
        font-weight: bold;
        font-size: 1.1rem;
        padding-left: 0.5rem;
      }
    }
  }
}
