.flights-container {
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }

  .one-way-container,
  .round-trip-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    //max-width: 18rem;

    .filter-background {
      background: $grey-light;
      border-radius: 0.2rem;
      padding: 1rem 1rem 1rem 0;

      .title-container {
        display: flex;
        flex-direction: row;
        width: 30rem;

        .button-for-reset {
          border: none;
          font-weight: bold;
          font-size: 0.9rem;
          color: #c3b289;
          padding-bottom: 1rem;
          padding-left: 1rem;
          background: transparent;
        }
      }

      .form-content {
        display: flex;
        flex: 1;
        flex-direction: row;

        .input-field {
          font-weight: 500;

          .react-date-picker__inputGroup {
            text-align: center;
          }

          .react-date-picker__inputGroup__year,
          .react-date-picker__inputGroup__input {
            padding: 0.5rem;
          }

          .react-date-picker__wrapper,
          .react-time-picker__wrapper {
            max-height: 2.5rem;
          }

          .react-date-picker__clear-button,
          .react-date-picker__button {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            max-height: 2.5rem;
          }

          .react-date-picker__inputGroup__month,
          .react-date-picker__inputGroup__day,
          .react-date-picker__inputGroup__year {
            padding: 0.5rem 0;
          }

          .react-date-picker__inputGroup__year {
            width: 2.8rem !important;
          }

          .react-time-picker__inputGroup__amPm {
            background: #fff;
            height: 2rem;
          }

          .react-time-picker__inputGroup__minute {
            min-width: 0.8rem;
            padding-left: 0;
          }

          .react-time-picker__inputGroup__input--hasLeadingZero {
            padding-left: 0;
          }

          .react-time-picker__inputGroup__divider {
            padding-right: 0;
          }

          .picker__inputGroup__input {
            padding: 0.5rem;
          }
        }

        .number-field {
          max-width: 5rem;
        }

        .flight-search-box {
          flex-direction: row;
          display: flex;
          justify-content: flex-start;
          padding-right: 0;

          .icon {
            color: $gold;
            padding-top: 0.8rem;
            height: 2rem;
          }

          #returnFrom,
          #returnTo {
            min-width: 17.5rem;
            margin-right: 0.4rem;
            border: solid 0.08rem black;
            border-radius: 0.3rem;
          }

          #returnTo {
            margin-right: 0;
          }

          input:read-only {
            background: rgba(255, 255, 255, 0.5);
          }
        }

        .separator {
          border-left: 1px solid rgba(0, 0, 0, 0.5);
          width: 0.1rem;
          height: 2rem;
          margin: 0.2rem 1rem 0 0;
        }

        .time-date-box {
          display: flex;
          flex-direction: row;

          .input-field {
            margin: 0;
          }

          .label-container {
            margin-top: -1.45rem;

            label {
              color: black;
              font-weight: 100;
              margin-top: -5.3rem;
            }
          }
        }

        .label-container {
          margin-top: -1.45rem;

          label {
            font-weight: 100;
            margin-top: -5.3rem;
          }
        }
      }
    }

    .single-field {
      padding: 0 1rem 0 0;
      margin: 0 0 0 1rem;
      width: 100%;
      justify-content: space-around;

      .filled-input div:nth-child(2n) {
        background: green;
        //display: none;none
      }

      .input {
        div div div:first-child {
          //padding-left: 1rem;
        }
      }

      //noinspection Stylelint
      input,
      .input {
        flex: 1;
        min-width: auto;
        width: 100%;
      }

      input[type='number'],
      input[type='password'],
      input[type='text'] {
        padding-top: 0.65rem;
        padding-bottom: 0.65rem;
      }
    }

    //noinspection Stylelint
    .select-field input {
      min-width: 14rem !important;
    }
  }

  .padding-footer {
    padding-top: 1rem;
    padding-left: 1rem;
  }

  .footer-container {
    padding: 0;

    .primary-button,
    .dark-button,
    .wired-button {
      width: 100%;
      margin: 0;
      font-size: 1rem;
      padding: 0.6rem 1rem;
    }
  }

  .multi-leg-footer {
    flex-direction: row;
    display: flex;
    justify-content: space-between;

    button {
      width: auto;

      &.wired-button {
        //margin-right: 1rem !important;
        max-height: 2.6rem;
      }
    }
  }
}

.react-calendar__tile {
  font-family: $primary-font;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.6rem 0.4rem;
}

.react-calendar__tile--now,
.react-calendar__tile--now:hover,
.react-calendar__tile--now:active {
  background: $gold !important;
  color: white;
}

.react-calendar__navigation__label__labelText {
  font-size: 0.9rem;
  font-weight: 500;
  font-family: $primary-font;
}
