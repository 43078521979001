.tab-bar-title {
  padding: 1rem 0 2rem 0;

  .tab-item {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3rem;
    background: none;
    border: none;
    border-bottom: solid 0.2rem lighten($gold, 30%);
    margin-right: 0.5rem;
    color: lighten(black, 40%);
    cursor: pointer;

    &.active {
      border-bottom: solid 0.2rem $gold;
      color: black;
    }
  }
}

.tab-container {
  @extend .columns;

  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;

  .filter-form {
    background: red;
  }
}
