.input-field {
  input,
  .input {
    border: none;
    border-bottom: solid 1px #4f4f4f;
    margin: 0 0 0.5rem;
    min-width: 17vw;
    padding: 0.5rem 0.5rem 0.5rem 0;
    font-size: 1rem;
    font-weight: 500;

    &:focus {
      border-color: $gold;
      outline: none;
    }
  }

  &.select-field {
    .input {
      border: none;
    }
  }

  &.single-field {
    padding: 1rem;

    input,
    .input {
      border: none;
      padding: 1rem;
      height: auto;
    }

    .input {
      padding: 0;
      min-width: 100%;
      border: none;
      border-radius: 0;
      background: white;
      font-family: $family-sans-serif;
    }
  }

  &.error-field {
    input {
      color: red;
      border-color: red;
    }
  }
}

.checkbox-field {
  margin: 1rem 0;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  display: flex;

  label {
    cursor: pointer;
    padding-left: 0.7rem;
    position: relative;
    top: -0.05rem;
  }

  .checkbox {
    border: solid 1px $gold;
    border-radius: 0;
    height: 1rem;
    background: white;
    width: 1rem;
    padding: 0;
    margin: 0 0.5rem 1rem 0;

    &.checked {
      background: $gold;
      margin: 0;
    }
  }

  &.error-field {
    label {
      color: red;
    }

    .checkbox {
      background: red;
    }
  }
}

.select-with-label {
  .select-label-container {
    label {
      padding-bottom: 0.5rem;
      display: block;
    }
  }
}

::-webkit-input-placeholder {
  @include placeholder();
}

::-moz-placeholder {
  @include placeholder();
}

:-ms-input-placeholder {
  @include placeholder();
}

:-moz-placeholder {
  @include placeholder();
}

.message-box {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  border-radius: 0.1rem;
  font-weight: 600;
  color: white;

  &.status-success {
    background: lighten(green, 20%);
  }

  &.status-error {
    background: lighten(red, 20%);
  }
}

.react-date-picker {
  //width: 100%;

  .react-date-picker__wrapper {
    background: white;
    margin-bottom: 0.5rem;
    padding: 0;
    border: none;
    font-family: $primary-font;

    .react-date-picker__inputGroup__input {
      padding: 0.3rem 0.5rem 0.5rem 0.5rem;
    }

    .react-calendar__tile {
      font-size: 1rem;
    }
  }
}

.react-time-picker {
  //width: 100%;
  overflow: hidden !important;

  .react-time-picker__wrapper {
    background: white;
    margin-bottom: 0.5rem;
    padding: 0;
    border: none;
    font-family: $primary-font;

    .react-time-picker__inputGroup__input {
      padding: 0.3rem 0 0.5rem 0.5rem;
    }

    .input-field .react-date-picker__inputGroup__year {
      width: 2.6rem !important;
    }
  }
}

.react-time-picker__inputGroup__amPm {
  -webkit-appearance: none !important;
  appearance: none !important;
  padding: 0 !important;
  font-size: 0.9rem;
}
