@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$gold: #c2b289;

// Update Bulma's global variables
$family-sans-serif: "Montserrat", sans-serif;
$primary-font: $family-sans-serif;
$grey-dark: #121212;
$grey-light: #f1f1f3;
$grey: #dfdfdf;
$primary: $gold;
$link: $gold;
$widescreen-enabled: true;
$fullhd-enabled: true;

$header-back: #393939;
$table-header: #f3f3f3;

// Update some of Bulma's component variables
$body-background-color: white;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

@import '~bulma';
