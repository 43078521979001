.flight-search-container {
  flex: 1;
  padding: 0 1rem 0 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  .empty-result-label {
    text-align: center;
    margin: 2rem auto;
    max-width: 25rem;

    .status-icon {
      color: $gold;
      padding-bottom: 0.5rem;
      font-size: 1.5rem;
      display: block;
      padding-right: 2rem;
    }
  }

  .flight-container {
    flex: 0.25;
    min-width: 16.7rem;
    max-height: 30.8rem;
    margin-bottom: 3rem;
    //flex: 1 1 2rem;
    background: white;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    overflow: hidden;
    margin-right: 1.3rem;

    &:nth-child(4n+4) {
      margin-right: 0;
    }

    .flight-item {
      .secondary {
        width: 100%;
        margin-top: 1rem;
        background: #c3b289;
        color: #000;
        border-radius: 0;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .flight-image-container img {
        object-fit: cover;
        align-self: center;
        height: 12rem;
        min-width: 100%;
      }
    }

    .item-header {
      border: none;
      padding: 1rem 1rem 0.5rem;

      .header-title {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1rem;
        color: $grey-dark;

        span.lower-text {
          float: right;
        }
      }

      .seats-avail {
        font-size: 2rem;
      }
    }

    .duration {
      flex: 1;
      text-align: center;
      font-size: 1rem;
      line-height: 1.3rem;
      color: #646464;
    }
  }
}

.flight-body {
  padding: 0 1rem 1rem;
  display: flex;
  flex-direction: row;

  .departure,
  .arrival {
    flex: 1;
    max-width: 50%;

    .location-lbl {
      font-weight: bold;
      font-size: 2rem;
      text-align: left;
      color: #c3b289;
      margin: 0;
      line-height: 2rem;
    }

    .city-label {
      margin: 0;
      padding: 0;
      max-height: 4rem;
    }

    &.arrival .city-label {
      text-align: right;
      align-self: flex-end;
    }

    .dateLbl {
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: left;
      color: #010000;
      padding-top: 0.5rem;

      strong {
        font-weight: 600;
        font-size: 1.3rem;
        display: block;
      }
    }
  }

  .arrival {
    text-align: right;

    .location-lbl {
      text-align: right;
    }

    .dateLbl {
      text-align: right;
    }
  }
}
