.search-bar {
  .search-field {
    background: white;
    display: inline-flex;
    flex-direction: row;
    border-radius: 0.3rem;
    flex: 1;
    min-width: 20rem;

    .search-button {
      background: transparent;
      color: lighten($grey-dark, 40%);
      margin: 0.1rem 0 0 0.3rem;
      font-size: 0.9rem;
      border: none;
    }

    input {
      flex: 1;
      background: white;
      border: none;
      font-size: 0.85vw;
      padding: 0.5rem 0.5rem 0.6rem 0;
      font-family: $family-sans-serif;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
      outline: none;
      //&:focus {
      //  background: darken(white, 10%);
      //}
    }
  }
}

.header-bar {
  background: $header-back;
  padding: 1rem;
  color: white;
  align-items: center;

  .header-logo {
    padding: 1rem;

    img {
      width: 8rem;
    }
  }

  .columns {
    justify-content: space-between;

    .logo-container {
      display: flex;
      flex-direction: row;
    }
  }

  .search-bar {
    @extend .column;

    justify-content: flex-start;
    padding-top: 1.7rem;
  }

  .icons-bar {
    padding: 1.8rem 2rem 0 2rem;
    display: flex;
    max-height: 5rem;

    .notification-item {
      position: relative;
      padding: 0.5rem 0.5rem 0 0.5rem;
      max-width: 2.5rem;
      max-height: 2.5rem;
      border-radius: 0.5rem;
      margin: 0 0 0 0.5rem;

      img {
        height: 1.5rem;
        object-fit: contain;
      }

      .notif-counter {
        position: absolute;
        background: $gold;
        font-size: 0.8rem;
        font-weight: bold;
        padding-top: 0.15rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1rem;
        color: white;
        align-items: center;
        text-align: center;
        left: 1.2rem;
        top: -0.2rem;
        border: solid 1px $grey-dark;
        box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 1);
      }

      &:hover {
        background: lighten($header-back, 10%);
      }
    }

    .separator {
      width: 0.07rem;
      height: 1.5rem;
      margin: 0.5rem 0.7rem 0 1rem;
      background: white;
    }
  }

  .profileItem {
    font-weight: bold;
    font-size: 0.85rem;
    padding: 0.5rem 0.5rem 0.5rem 0;
    display: flex;
    flex-direction: row;

    span {
      color: white;
      font: $primary-font;
    }

    .avatar {
      margin: 0 0.5rem;
      height: 3rem;
      width: 3rem;
      background: $gold;
      border-radius: 1.5rem;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      top: -0.9rem;
      border: none;

      img {
        height: 3rem;
        width: 3rem;
      }

      p {
        color: white;
        flex: 1;
        padding-top: 0.4rem;
        font-size: 1.4rem;
        font-weight: normal;
        text-align: center !important;
      }
    }
  }
}

.Toastify__toast {
  border-radius: 0.5rem;
  overflow: hidden;
  padding-left: 1rem;
}

.Toastify__toast-body {
  font-family: $primary-font;
  color: $grey-dark;
  font-size: 0.9rem;
  font-weight: 500;
}

.Toastify__toast--error,
.Toastify__toast--info,
.Toastify__toast--success {
  .Toastify__toast-body {
    color: white;
  }
}

.Toastify__progress-bar {
  display: none;
}
