.card-orders {
  flex: 0.18;
  flex-direction: column;
  height: 6vw;
  border: none;
  font-size: 1vw;
  font-weight: 700;
  padding: 1vw;
  background: white;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);

  &.activeCard {
    border-bottom: solid 0.3rem $gold;
  }
}

.search-form-field-orders {
  flex: 0.19;

  .content,
  .checkbox-content {
    @extend .column;

    background: white;
    box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 0;
    margin-bottom: 0;

    .checkbox-field {
      min-width: 150px;
    }

    input {
      flex: 1;
      border: none;
      font-size: 0.95vw;
      padding: 0.8vw 0.5vw;
    }

    .search-icon {
      border: none;
      background: none;
      padding: 0 0 0 0.5rem;
      flex: 0.2;
      color: $gold;
    }
  }
}

.orders-page {
  table.orders-list {
    td {
      img {
        max-width: 7rem;
      }

      .price-amount,
      .price-currency {
        font-size: 1.3rem;
      }

      &.image-cell {
        width: 7rem;
        padding: 0;
      }

      &.fit-cell {
        padding-left: 2rem;
      }

      &.status-cell {
        width: 6rem;
      }
    }
  }

  .tab-bar-title {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.edit-order-cell {
  max-width: 4rem;
  width: 4rem;
}

.edit-cell {
  text-align: center;
  display: flex;
  align-items: center;

  a {
    margin: auto;
  }
}

.order-client-container {
  @extend .client-info-container;

  .order-client-content {
    @extend .info-content;

    max-width: 50vw;

    .client-content {
      h1 {
        text-transform: uppercase;
        margin-bottom: 0;
      }

      h2 {
        font-weight: 600;
        font-size: 1rem;
        margin-top: 0.5rem;
      }

      .add-control {
        margin-top: 0.3rem;
        display: flex;
        flex-direction: row;

        .add-client-button {
          padding: 0 1rem !important;
          font-size: 0.9rem;
          max-height: 2.6rem;
        }
      }
    }
  }
}

$borderSpace: 1rem;

.container-order-details {
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  padding: 1.5rem;

  .columns {
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .column {
      padding: 0;
      margin: 0;
      flex: 1;
      max-width: 20rem;

      h2 {
        font-size: 1.5rem;
        font-weight: 500;
        padding-bottom: 1rem;
        margin-bottom: 0;

        &.ml1 {
          margin-left: 1rem;
        }

        &.mt1 {
          padding-top: 1rem;
        }
      }

      img {
        max-width: 80%;
      }

      table {
        max-width: 20rem;
        margin-top: 0;

        td {
          padding: $borderSpace 0.5rem;
          border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
          margin-top: $borderSpace;
          vertical-align: middle;
        }

        tr:last-child {
          td {
            border: none;
          }
        }
      }

      .leg-info {
        border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
        padding-bottom: $borderSpace;
        margin-bottom: $borderSpace;

        &:last-child {
          border: none;
          margin-bottom: 0;
        }
      }
    }

    .separator {
      flex: 1;
      max-width: 0.08rem;
      background: rgba(0, 0, 0, 0.1);
      margin: 1rem 0;
    }
  }
}

.offer-details-container {
  @extend .container-order-details;

  margin-bottom: 3rem;

  h2 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .leg-info {
    padding-left: 0;
    padding-right: 0;
  }

  .client-picker {
    max-height: 30rem;
    overflow: auto;
    margin-top: 1rem;

    .client-picker-item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1rem;

      button {
        @include iconButton();

        padding: 0.2rem 1rem;
        max-height: 2.6rem;
        border-radius: 0.5rem;
        margin-right: 1rem;

        &:hover {
          background: $gold;
          color: black;
        }
      }

      p {
        font-weight: 500;

        &:hover {
          cursor: pointer;
        }

        span {
          font-weight: 300;
          display: block;
        }
      }
    }
  }

  .button-container-details {
    padding-top: 1rem;

    .button-cancel {
      margin-left: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 0.4rem;
    }
  }

  .selected-clients {
    @extend .column;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .selected-clients-list {
      justify-content: space-between;
      flex: 1;
      max-height: 25rem;
      overflow: auto;
      margin-top: 0.5rem;

      &.fixed-padding {
        position: relative;
        top: -2.5rem;
      }

      .selected-client-item {
        display: flex;
        flex-direction: row;
        padding: 0.5rem;
        justify-content: space-between;
        border-radius: 0.5rem;
        width: 95%;

        &:hover {
          background: $grey;
        }

        button {
          @include iconButton();
        }

        p {
          font-weight: 500;

          span {
            display: block;
            font-weight: 300;
          }
        }
      }
    }
  }

  .comment {
    @extend .selected-clients;

    min-width: 32%;
  }

  .quote-client-comments {
    padding: 1rem;
    width: 90%;
    border: solid 1px rgba(0, 0, 0, 0.5);
    min-height: 15rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }
}

.price-per-person {
  input {
    min-width: 1rem;
    width: 100%;
    font-size: 2rem;
    align-self: flex-end;
    text-align: center;
  }

  ::placeholder {
    font-size: 2rem;
    font-weight: 500;
  }
}

.offer-confirmation {
  .client-content {
    flex: 1;
    padding: 0.5rem;

    textarea {
      padding: 1rem;
      font-size: 1rem;
      border: none;
      width: 100%;
      flex: 1;
      border-radius: 0.5rem;
      border-bottom: solid 1px #4f4f4f;
      background-color: lighten($grey-light, 3%);
      margin: 1rem 0;
      height: 20rem;
    }

    .info-note {
      font-size: 0.8rem;
      padding: 0.5rem 0 1rem 0;
    }

    .titleContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h1 {
        padding-top: 0.2rem;
      }
    }

    .add-control {
      margin-top: 1rem;

      button {
        margin-right: 0.5rem;
        padding: 0.5rem 1rem;
        width: auto;
        border-radius: 0.5rem;
      }
    }
  }
}
